import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntegrations } from '../../state/integrations';
import useStateLoading from '../../hooks/useStateLoading';
import { OverlaySpinner } from '../../components/Spinner';
import { NoProject } from '../../components/NoProject';
import {
  ACTIVE_ACCOUNT_ID,
  ACTIVE_ACCOUNT_NAME,
  ACTIVE_PROJECT_ID,
  ACTIVE_PROJECT_NAME,
} from '../../constants';

export const AiIntegration: React.FC = () => {
  const { t } = useTranslation('pages_title');
  const { aiIntegrationClient, isFetching } = useIntegrations();
  const { isStateLoading, isProject } = useStateLoading();
  const [isIframeLoading, setIframeLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<URL>();

  useEffect(() => {
    setIframeLoading(true);

    if (aiIntegrationClient?.url) {
      setUrl(new URL(aiIntegrationClient.url));
    }
  }, [aiIntegrationClient?.url]);

  useEffect(() => {
    if (url) {
      url?.searchParams.append(
        ACTIVE_PROJECT_ID,
        localStorage.getItem(ACTIVE_PROJECT_ID) as string,
      );

      url?.searchParams.append(
        ACTIVE_PROJECT_NAME,
        localStorage.getItem(ACTIVE_PROJECT_NAME) as string,
      );

      url?.searchParams.append(
        ACTIVE_ACCOUNT_ID,
        localStorage.getItem(ACTIVE_ACCOUNT_ID) as string,
      );

      url?.searchParams.append(
        ACTIVE_ACCOUNT_NAME,
        localStorage.getItem(ACTIVE_ACCOUNT_NAME) as string,
      );
    }
  }, [url]);

  if (!isProject && !isStateLoading) return <NoProject />;
  return (
    <>
      {url && (
        <iframe
          title={t('integration').toLowerCase()}
          src={url?.toString()}
          onLoad={() => setIframeLoading(false)}
        />
      )}
      <OverlaySpinner loading={isFetching || isIframeLoading} />
    </>
  );
};
