import React, { useState } from 'react';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { DuplicateModal } from './DuplicateModal';
import { TableActionButton } from '../../../../components/DataTable/DataTable.styles';

interface Props {
  id: string | number;
  disabled?: boolean;
  loading?: boolean;
}

export const DuplicateItem: React.FC<Props> = ({ id, disabled, loading }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);

  return (
    <>
      <TableActionButton onClick={() => showModal()} disabled={disabled} loading={loading}>
        <CopyIcon width={13} />
      </TableActionButton>
      <DuplicateModal id={id.toString()} isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
};
