import React from 'react';
import { useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/es/interface';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { AgenticModelsForm } from '../AgenticModelsForm';
import { useCreate } from '../../../../../hooks/useCreate';
import { AgenticModelsService } from '../../../../../services/ai/agentic-models';
import { useIntegrations } from '../../../../../state/integrations';
import { IAgenticModelValues } from '../types';

export const AgenticModelsNew = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();

  const initialValues = {
    name: '',
    provider: undefined,
    model: undefined,
    api_key: '',
  };

  const { mutate: createItem, isLoading } = useCreate(
    [AgenticModelsService.queryKey],
    AgenticModelsService.create,
  );

  const onSubmit = async (
    data: IAgenticModelValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    return createItem(
      { apiData: aiIntegration, data },
      {
        onSuccess: data => {
          notification.success({
            message: (
              <Trans t={t}>
                {t('model_created_notify', {
                  name: data?.agentic_model.name,
                })}
              </Trans>
            ),
          });
          navigate(-1);
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  };

  return (
    <Content imgBg={false}>
      <AgenticModelsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="create" htmlType="submit" loading={isLoading} />}
      />
    </Content>
  );
};
