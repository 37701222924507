import { pipe, map, reduce, mergeRight, toPairs } from 'ramda';
import { IContextParameter } from '../pages/AI/Automation/Contexts/types';

export const formatParamName = (name: string): string => name.replace(/(?!%)([^\w-])/gi, '');

export const formatParameter = (item: IContextParameter): Record<string, string | undefined> => ({
  [formatParamName(item.name)]: item.value?.trim() ?? '',
});

export const transformParameters = (data: IContextParameter[]): string =>
  JSON.stringify(pipe(map(formatParameter), reduce(mergeRight, {}))(data));

export const retainUnderscoreHyphenPercent = (str: string): string =>
  str.replace(/(?!%)([^\w-])/gi, '');

export const objToArray = pipe(
  toPairs,
  map(([name, value]) => ({ name: name as string, value: value as string | undefined })),
);

export const isFieldNotUnique = (
  parent: {
    [key: string]: any;
  }[],
  key: string,
  value: string,
  index: number,
): boolean => {
  return parent
    .filter((_, i) => i !== index)
    .some(v => v[key]?.toLowerCase() === value?.toLowerCase());
};
