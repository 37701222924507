import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../AI.styles';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';

export const ConfigurationList: React.FC = () => {
  const { t } = useTranslation('ai');

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('fallback_behav')} bordered={false}>
            <CardInfo>{t('def_cust_exp')}</CardInfo>
            <ViewLink to={Paths.aiFallbackBehaviour()} />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('knowledge_bases')} bordered={false}>
            <CardInfo>{t('knowledge_bases_description')}</CardInfo>
            <ViewLink to={Paths.aiKnowledgeBases()} />
          </StyledCard>
        </Col>
      </Row>
    </Content>
  );
};
