import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';

interface IAutomatedChannel {
  id: number;
  name: string;
}

const queryKey = 'automated-channels';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomatedChannel[]> => {
  const response = await integrationQuery(`/api/automated_channels`, {
    apiData,
    signal,
  });
  return response?.automated_channels;
};

export const AutomatedChannelsService = {
  queryKey,
  get,
};
