import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ThemeType } from '../../theme';

const Root = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: ${props => props.theme.navWidth};
  height: 100%;
  z-index: 2;
`;

const Logo = styled.button`
  width: 100%;
  height: ${props => props.theme.headerHeight};
  background: ${({ theme }) => theme.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 none;
  transition: background 0.15s;
  cursor: pointer;
  & svg {
    transform: translate(-2px, -2px);
    pointer-events: none;
    path,
    polygon {
      transition: fill 0.15s;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.secondaryColor};
    svg path,
    svg polygon {
      fill: ${({ theme }) => theme.primaryColor};
    }
  }
`;

const commonStyle = ({ theme }: { theme: ThemeType }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 9px;
  letter-spacing: -0.2px;
  font-weight: 600;
  font-family: ${theme.fontFamilyAccent};
  text-transform: uppercase;
  text-align: center;
  transition: color 0.3s ease, background-color 0.3s ease;

  &.active,
  &:hover {
    background-color: ${theme.secondaryColor};
    color: ${theme.primaryColor};
  }

  svg {
    margin-bottom: 4px;
    pointer-events: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 128px);
  background: var(--blue-color);
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &:before {
    top: 0;
    border-width: 0 64px 9px 0;
    border-color: transparent rgb(255, 255, 255) transparent transparent;
  }
  &:after {
    bottom: 0;
    border-width: 9px 0 0 64px;
    border-color: transparent transparent transparent rgb(255, 255, 255);
  }
`;

const NavItem = styled(NavLink)`
  flex: 0 1 auto;
  height: 90px;
  ${commonStyle};
  padding: 10px 0;
  font-size: 8px;
`;

const TempNavItem = styled(NavLink)`
  flex: 0 1 auto;
  margin-top: auto;
  height: 90px;
  ${commonStyle};
  padding: 10px 0;
  background-color: ${({ theme }) => theme.primaryColor};
  font-size: 8px;
`;

const NewLabel = styled.span`
  color: white;
  background-color: ${({ theme }) => theme.redCollor};
  margin-left: 50%;
  border-radius: 10px;
  width: 25px;
`;

const HelpButton = styled.a`
  width: 100%;
  height: 64px;
  background-color: ${({ theme }) => theme.primaryColor};
  ${commonStyle};
`;

export const StyledNavbar = {
  Root,
  Logo,
  Nav,
  NavItem,
  TempNavItem,
  NewLabel,
  HelpButton,
};
