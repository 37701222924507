import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  FallbackBehaviourValues,
  IFallbackConfig,
} from '../../pages/AI/Configuration/FallbackBehaviour/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'fallback-configs';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IFallbackConfig[]> => {
  const response = await integrationQuery(`/api/fallback_configs`, {
    apiData,
    signal,
  });
  return response?.fallback_configs;
};

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IFallbackConfig> => {
  const response = await integrationQuery(`/api/fallback_configs/${id}`, { apiData, signal });
  return response?.fallback_config;
};

const create = async ({ data, apiData }: { data: FallbackBehaviourValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/fallback_configs`, {
    method: 'POST',
    data: { data: { type: 'fallback_configs', attributes: data } },
    apiData,
  });

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<FallbackBehaviourValues>;
}): Promise<IFallbackConfig> => {
  return integrationQuery(`/api/fallback_configs/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'fallback_configs',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/fallback_configs/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const FallbackConfigsService = {
  queryKey,
  get,
  find,
  create,
  update,
  remove,
};
