import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { AnalyticsParams, AnalyticsService } from '../../../../../services/analytics';
import { Reports } from '../../types';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';

export const ExploreList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { analytics: { url, airlineId, externalId } = {} } = useIntegrations();

  const { isLoading, data, isFetching } = useQuery<Reports[]>(
    [AnalyticsService.queryKeyExplores, externalId, airlineId],
    ({ signal }) =>
      AnalyticsService.getExplores(
        {
          projectId: externalId,
          componentId: airlineId,
          analyticsUrl: url,
        } as AnalyticsParams,
        signal,
      ),
    { enabled: !!externalId && !!airlineId },
  );

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLinkBold to=""> {t('explore')}</UppercaseLinkBold>
      <ReportsTable
        loading={!!externalId && !!airlineId && (isLoading || isFetching)}
        reports={data || []}
        type="explore"
      />
    </Content>
  );
};
