import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IConversationProfile,
  IConversationProfileValues,
} from '../../pages/AI/Automation/ConversationProfiles/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'conversation-profiles';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IConversationProfile[]> => {
  const response = await integrationQuery(
    `/api/dialogflow/conversation_profiles?includes[]=dialogflow_agent`,
    {
      apiData,
      signal,
    },
  );
  return response?.['dialogflow/conversation_profiles'];
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/conversation_profiles/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const create = async ({
  data,
  apiData,
}: { data: IConversationProfileValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/conversation_profiles`, {
    method: 'POST',
    data: { data: { type: 'conversation_profiles', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IConversationProfile> => {
  const response = await integrationQuery(`/api/dialogflow/conversation_profiles/${id}`, {
    apiData,
    signal,
  });
  return response?.['dialogflow/conversation_profile'];
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IConversationProfileValues>;
}): Promise<IConversationProfile> => {
  return integrationQuery(`/api/dialogflow/conversation_profiles/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'conversation_profiles',
        attributes: data,
      },
    },
    apiData,
  });
};

export const ConversationProfilesService = {
  queryKey,
  get,
  remove,
  find,
  create,
  update,
};
