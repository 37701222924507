import React from 'react';
import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Analytics } from './index';
import { DashboardsList } from './Reports/Dashboards/DashboardsList';
import { LooksList } from './Reports/Looks/LooksList';
import { ExploreList } from './Reports/Explore/ExploreList';
import { FAReportsList } from '../Analytics/Reports/FAReports/FAReportsList';
import { LookerIframe } from './Reports/LookerIframe';
import { FAReportIframe } from '../Analytics/Reports/FAReports/FAReportIframe';
import { ConfigurationsList } from '../Analytics/Configurations/ConfigurationsList';
import { AnalyticsWebhook } from '../Analytics/Configurations/AnalyticsWebhook';
import { DLPTemplateList } from '../Analytics/Configurations/DLPTemplate/DLPTemplateList';
import { DLPTemplateNew } from '../Analytics/Configurations/DLPTemplate/DLPTemplateNew';
import { DLPTemplateEdit } from '../Analytics/Configurations/DLPTemplate/DLPTemplateEdit';
import { DataRetentionPolicyList } from '../Analytics/Configurations/DataRetentionPolicy/DataRetentionPolicyList';
import { DataRetentionPolicyEdit } from '../Analytics/Configurations/DataRetentionPolicy/DataRetentionPolicyEdit';
import { DeleteConversations } from '../Analytics/Configurations/DeleteConversations';
import { Configurations } from '../Analytics/Configurations';

export const oldAnalyticRoutes = () => ({
  path: Paths.oldAnalytics(),
  element: <Analytics />,
  breadcrumb: null,
  children: [
    {
      index: true,
      element: <Navigate replace to={Paths.oldAnalyticDashboards()} />,
      breadcrumb: null,
    },
    {
      path: Paths.oldAnalyticDashboards(),
      element: <DashboardsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('dashboards')}</Translation>,
    },
    {
      path: Paths.oldAnalyticLooks(),
      element: <LooksList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('looks')}</Translation>,
    },
    {
      path: Paths.oldAnalyticExplore(),
      element: <ExploreList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('explore')}</Translation>,
    },
    {
      path: Paths.oldAnalyticFAReports(),
      element: <FAReportsList />,
      breadcrumb: () => <Translation ns={'reports'}>{t => t('reports')}</Translation>,
    },
    {
      path: Paths.oldAnalyticConfigurations(),
      element: <Configurations />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configurations')}</Translation>,
      children: [
        {
          index: true,
          element: <ConfigurationsList />,
        },
        {
          path: Paths.oldAnalyticWebhook(),
          element: <AnalyticsWebhook />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('ext_webhook_req')}</Translation>,
        },
        {
          path: Paths.oldAnalyticDLP(),
          element: <DLPTemplateList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('dlp_template')}</Translation>,
        },
        {
          path: Paths.oldAnalyticDLPCreate(),
          element: <DLPTemplateNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.oldAnalyticDLPEdit(),
          element: <DLPTemplateEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.oldAnalyticDataRetentionPolicy(),
          element: <DataRetentionPolicyList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('data_retention_policy')}</Translation>,
        },
        {
          path: Paths.oldAnalyticDataRetentionPolicyEdit(),
          element: <DataRetentionPolicyEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.oldAnalyticsDeleteConversations(),
          element: <DeleteConversations />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('delete_conversations')}</Translation>,
        },
      ],
    },
    { path: Paths.oldAnalyticDashboardView(), element: <LookerIframe /> },
    { path: Paths.oldAnalyticLookView(), element: <LookerIframe /> },
    { path: Paths.oldAnalyticExploreView(), element: <LookerIframe /> },
    { path: Paths.oldAnalyticFAReportView(), element: <FAReportIframe /> },
  ],
});
