import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation, Translation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactComponent as ChartIcon } from '../../assets/icons/chart-bar.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg';
import { ReactComponent as ExploreIcon } from '../../assets/icons/explore.svg';
import { ReactComponent as FAReportsIcon } from '../../assets/icons/fAReports.svg';
import { ReactComponent as ConfigIcon } from '../../assets/icons/config.svg';
import { FixedNav } from '../../components/FixedNav';
import { useIntegrations } from '../../state/integrations';
import { useAccount } from '../../state/account';
import { AiIntegrationParams } from '../../services/api';
import { Reports } from '../Analytics/Reports/types';
import { AnalyticsAirlineService } from '../../services/analytics-airline';
import { AnalyticsMenuTabs, DashboardsMenuArrow } from '../Analytics/Analyics.styles';
import { useUpdateEffect } from '../../hooks/useUpdateEffect';

type MenuItems = { key: string; label: JSX.Element }[];

export const AnalyticsMenu = (): JSX.Element => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const { ai, flexAnswerCustomer, switchToConsoleAnalytics } = useFlags();
  const location = useLocation();
  const { activeProjectId } = useAccount();
  const [key, setKey] = useState<string>(location.pathname.split('/')[2]);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState<string>(
    location.pathname.split('/')[3],
  );
  const [options, setOptions] = useState<MenuItems>([]);
  const { aiIntegration } = useIntegrations();

  const { data } = useQuery<Reports[]>(
    [
      AnalyticsAirlineService.queryKeyDashboards,
      activeProjectId,
      aiIntegration?.metadata.PROJECT_ID,
      aiIntegration?.url,
    ],
    ({ signal }) =>
      AnalyticsAirlineService.getDashboards(
        { apiData: aiIntegration } as AiIntegrationParams,
        signal,
      ),
    { enabled: !!aiIntegration?.url },
  );

  useUpdateEffect(() => {
    navigate(switchToConsoleAnalytics ? `/analytics/${key ?? ''}` : `/old-analytics/${key ?? ''}`);
  }, [switchToConsoleAnalytics]);

  useEffect(() => {
    setActiveSubMenuItem(location.pathname.split('/')[3]);
    setKey(location.pathname.split('/')[2]);
  }, [location.pathname]);

  useEffect(() => {
    const options = data?.map((item: Reports) => ({
      className: activeSubMenuItem === item.id && 'sub-menu-item-active',
      key: item.id.toString(),
      label: (
        <Link
          to={`/old-analytics/dashboards/${item.id}`}
          state={{ name: item.name, type: 'dashboards', url: item.url }}
          className="submenu-item"
        >
          {item.name}
        </Link>
      ),
    }));
    setOptions(options ? options : []);
  }, [data, activeSubMenuItem]);

  const menuItems = [
    {
      style: { paddingRight: '10px' },
      key: 'dashboards',
      icon: <ChartIcon width={15} height={15} />,
      label: (
        <>
          {t('dashboards')}
          <DashboardsMenuArrow />
        </>
      ),
      children: [
        ...options,
        options.length > 0 && {
          key: 'divider',
          type: 'divider',
        },
        {
          className: !activeSubMenuItem && key === 'dashboards' && 'sub-menu-item-active',
          key: 'all',
          label:
            options.length > 0 ? (
              <Link className="submenu-item" to="/old-analytics/dashboards">
                {t('view-all-dashboards')}
              </Link>
            ) : (
              <span className="submenu-item">{t('no-dashboards-available')}</span>
            ),
        },
      ],
    },
    {
      key: 'looks',
      icon: <EyeIcon width={15} height={15} />,
      label: <Link to="/old-analytics/looks">{t('looks')}</Link>,
    },
    {
      key: 'explore',
      icon: <ExploreIcon width={15} height={15} />,
      label: <Link to="/old-analytics/explore">{t('explore')}</Link>,
    },
  ];
  if (flexAnswerCustomer)
    menuItems.push({
      key: 'fAReports',
      icon: <FAReportsIcon width={15} height={15} />,
      label: <Link to="/old-analytics/fAReports">{t('reports')}</Link>,
    });

  if (ai)
    menuItems.push({
      key: 'configurations',
      icon: <ConfigIcon width={15} height={15} />,
      label: (
        <Link to="/old-analytics/configurations">
          <Translation ns={'ai'}>{t => t('configurations')}</Translation>
        </Link>
      ),
    });

  return (
    <FixedNav>
      <AnalyticsMenuTabs
        mode="horizontal"
        selectedKeys={[key]}
        items={menuItems}
        triggerSubMenuAction="click"
      />
    </FixedNav>
  );
};
