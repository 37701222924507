import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from './api';
import { IAiIntegrationUrl } from '../state/integrations';
import { IAutomationProfile, IVAConfig, IVAConfigPayload } from '../pages/Design/types';

const queryKey = 'design';
const queryKeyProfiles = 'design-profiles';

export type VAConfigCreateParams = {
  apiData: IAiIntegrationUrl;
  payload: Partial<IVAConfigPayload>;
  avatarUrl?: string;
};

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IVAConfig[]> => {
  const response = await integrationQuery(`/api/virtual_agent_configs?includes=dial_plans`, {
    apiData,
    signal,
  });
  return response?.virtual_agent_configs;
};

const getProfiles = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IAutomationProfile[]> => {
  const response = await integrationQuery(
    `/api/dial_plans?channel_type=web&includes=virtual_agent_config`,
    {
      apiData,
      signal,
    },
  );
  return response?.dial_plans;
};

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IVAConfig> => {
  const response = await integrationQuery(`/api/virtual_agent_configs/${id}`, { apiData, signal });
  return response?.virtual_agent_config;
};

const create = async ({ apiData, payload, avatarUrl }: VAConfigCreateParams): Promise<IVAConfig> =>
  await integrationQuery(`/api/virtual_agent_configs`, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    apiData,
    data: {
      data: {
        attributes: payload,
      },
      ...(avatarUrl && { avatar_url: avatarUrl }),
    },
  });

const update = ({
  id,
  apiData,
  payload,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  payload: Partial<IVAConfigPayload>;
}): Promise<IVAConfig> => {
  return integrationQuery(`/api/virtual_agent_configs/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data' },
    apiData,
    data: {
      data: {
        attributes: payload,
      },
    },
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/virtual_agent_configs/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const DesignService = {
  queryKey,
  queryKeyProfiles,
  get,
  getProfiles,
  find,
  create,
  update,
  remove,
};
