import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { ITeams, ITeamsValues } from '../../pages/AI/Routing/Teams/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'teams';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ITeams[]> => {
  const response = await integrationQuery(
    `/api/teams?includes[]=router&includes[]=time_schedules`,
    {
      apiData,
      signal,
    },
  );
  return response?.teams;
};

const create = async ({ data, apiData }: { data: ITeamsValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/teams`, {
    method: 'POST',
    data: { data: { type: 'teams', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<ITeams> => {
  const response = await integrationQuery(
    `/api/teams/${id}?includes[]=router&includes[]=time_schedules`,
    {
      apiData,
      signal,
    },
  );
  return response?.team;
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<ITeamsValues>;
}): Promise<ITeams> => {
  return integrationQuery(`/api/teams/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'teams',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/teams/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const TeamsService = {
  queryKey,
  get,
  create,
  find,
  update,
  remove,
};
