import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../../state/account';
import { Paths } from '../../types/paths';

export const HomeMain = () => {
  const { ldContext } = useAccount();
  const { flexAnswerCustomer, switchToConsoleAnalytics } = useFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (ldContext && !ldContext.user.anonymous) {
      // FlexAnswer customers need to be authenticated first by navigating to knowledge, to access fAReports in analytics
      const targetRoute = flexAnswerCustomer
        ? Paths.oldKnowledge()
        : switchToConsoleAnalytics
        ? Paths.analytics()
        : Paths.oldAnalytics();

      navigate(targetRoute);
    }
  }, [flexAnswerCustomer, switchToConsoleAnalytics, ldContext?.user]);

  return <div />;
};
