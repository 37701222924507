import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { DataRententionPolicyService } from '../../../../../services/ai/data-retention-policy';
import { IDataRetentionPolicy } from '../types';
import { IconIndicator } from '../../../../../components/IconIndicator';
import { snakeToCapitalCase } from '../../../../../utils/string';
import { Paths } from '../../../../../types/paths';

export const DataRetentionPolicyList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { switchToConsoleAnalytics } = useFlags();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () =>
      navigate(
        switchToConsoleAnalytics
          ? Paths.analyticDataRetentionPolicyEdit({ id })
          : Paths.oldAnalyticDataRetentionPolicyEdit({ id }),
      ),
  });

  const columns: ColumnProps<IDataRetentionPolicy>[] = [
    {
      title: t('type'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '60%',
      render: record => snakeToCapitalCase(record),
    },
    {
      title: t('duration'),
      onCell: onCellHandler,
      width: '20%',
      render: record =>
        `${record.time_amount} ${
          record.time_amount > 1 ? record.time_unit + 's' : record.time_unit
        }`,
    },
    {
      title: t('enabled'),
      onCell: onCellHandler,
      dataIndex: 'is_enabled',
      width: '20%',
      render: record => <IconIndicator enabled={record} />,
    },
  ];

  return (
    <AiFeatureList<IDataRetentionPolicy>
      service={DataRententionPolicyService}
      columns={columns}
      getDisplayName={record => record.name}
    />
  );
};
