import React from 'react';
import { App } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FormInstance } from 'rc-field-form/es/interface';
import { AgenticModelsForm } from '../AgenticModelsForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { AgenticModelsService } from '../../../../../services/ai/agentic-models';
import { Content } from '../../../../../components/Content';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { unexpectedError } from '../../../../../utils/notification';
import { IAgenticModel, IAgenticModelValues } from '../types';

export const AgenticModelsEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IAgenticModel>(
    [AgenticModelsService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      AgenticModelsService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [AgenticModelsService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    AgenticModelsService.update,
  );

  const onSubmit = async (
    data: IAgenticModelValues,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    aiIntegration &&
      updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>{t('model_updated_notify', { name: data.agentic_model.name })}</Trans>
              ),
            });
            navigate(-1);
          },
          onError: (error: any) => {
            if (error.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...error.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      );
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: IAgenticModelValues = {
    name: data.name,
    provider: data.provider,
    model: data.model,
    api_key: data.api_key,
  };

  return (
    <Content imgBg={false}>
      <AgenticModelsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
      />
    </Content>
  );
};
