import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IKnowledgeBases,
  IKnowledgeBasesValues,
} from '../../pages/AI/Configuration/KnowledgeBases/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'knowledge-bases';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IKnowledgeBases[]> => {
  const response = await integrationQuery(`/api/dialogflow/knowledge_bases`, {
    apiData,
    signal,
  });
  return response?.['dialogflow/knowledge_bases'];
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/knowledge_bases/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const create = async ({ data, apiData }: { data: IKnowledgeBasesValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/knowledge_bases`, {
    method: 'POST',
    data: { data: { type: 'knowledge_bases', attributes: data } },
    apiData,
  });

const find = async (
  { id, apiData }: { id: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IKnowledgeBases> => {
  const response = await integrationQuery(`/api/dialogflow/knowledge_bases/${id}`, {
    apiData,
    signal,
  });
  return response?.['dialogflow/knowledge_base'];
};

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IKnowledgeBasesValues>;
}): Promise<IKnowledgeBases> => {
  return integrationQuery(`/api/dialogflow/knowledge_bases/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'knowledge_bases',
        attributes: data,
      },
    },
    apiData,
  });
};

export const KnowledgeBasesService = {
  queryKey,
  get,
  find,
  create,
  update,
  remove,
};
