import React, { FocusEvent, useRef, useState } from 'react';
import { Form, Input, Popconfirm } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useTranslation } from 'react-i18next';
import { formItemClass } from '../../theme';
import { Space } from '../Space';
import { UploadButton } from '../UploadButton';
import { getGCPKeyfile, getKeyfileProjectId, normalizeFile } from '../../utils/files';
import { hyphenateString } from '../../utils/string';
import { ReactComponent as ArrowIcon } from '../../assets/icons/chevron-double-right.svg';
import { IDFAgentValues } from '../../pages/AI/Automation/NLUProviders/types';

export const GoogleCloudProjectInput = ({
  initialValues,
  isEditing,
  isProjectCreation,
  isAIAutoSelected,
  isDLP,
}: {
  initialValues?: IDFAgentValues;
  isEditing?: boolean;
  isProjectCreation?: boolean;
  isAIAutoSelected?: boolean;
  isDLP?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('project_settings');
  const { validateFields, setFieldValue, getFieldValue } = Form.useFormInstance();
  const [popupVisible, setPopupVisible] = useState(false);
  const [keyfileProjectId, setKeyfileProjectId] = useState('');
  const projectNameTimeout = useRef<NodeJS.Timeout>();

  const getFieldName = (baseName: string) =>
    isProjectCreation ? ['ai_n_a_attributes', baseName] : baseName;

  const checkProjectName = async (projectName: string, keyfile: UploadFile[]) => {
    if (projectName && keyfile) {
      const result = await getGCPKeyfile(keyfile);
      const projectId = result && getKeyfileProjectId(result);
      if (projectId) {
        setPopupVisible(projectId !== projectName);
        setKeyfileProjectId(projectId);
      }
    }
    if (isEditing && initialValues?.gcp_keyfile) {
      const result = JSON.parse(initialValues.gcp_keyfile);
      const projectId = result.project_id;
      setPopupVisible(projectId !== projectName);
      setKeyfileProjectId(projectId);
    }
  };

  const handleProjectIdBlur = (event: FocusEvent<HTMLInputElement>) =>
    setFieldValue(getFieldName('gcp_project_name'), hyphenateString(event.target.value));

  const handleProjectIdChange = () => {
    if (projectNameTimeout.current) {
      clearTimeout(projectNameTimeout.current);
    }
    projectNameTimeout.current = setTimeout(async () => {
      await checkProjectName(
        getFieldValue(getFieldName('gcp_project_name')),
        getFieldValue(getFieldName('gcp_keyfile')),
      );
    }, 500);
  };

  const confirm = () => setPopupVisible(false);

  const handleProjectNameCancel = () => {
    setFieldValue(getFieldName('gcp_project_name'), keyfileProjectId);
    setPopupVisible(false);
  };

  const handleKeyfileChange = async () => {
    try {
      await validateFields([getFieldName('gcp_keyfile')]);
    } catch {
      setKeyfileProjectId('');
      setPopupVisible(false);
    }
    await checkProjectName(
      getFieldValue(getFieldName('gcp_project_name')),
      getFieldValue(getFieldName('gcp_keyfile')),
    );
  };

  return (
    <>
      <Space>
        {!isDLP && (
          <Form.Item
            name={getFieldName('display_name')}
            label={t('agent_name')}
            className={formItemClass.base}
            rules={[{ required: isAIAutoSelected || true }]}
          >
            <Input placeholder={t('agent_name')} />
          </Form.Item>
        )}
        <Popconfirm
          title={t('project_id_error')}
          onConfirm={confirm}
          onCancel={handleProjectNameCancel}
          placement="right"
          open={popupVisible}
          okText={t('yes')}
          okButtonProps={{ icon: <ArrowIcon width={14} height={14} /> }}
          cancelText={t('use_id_keyfile')}
          icon={<></>}
        >
          <Form.Item
            name={getFieldName('gcp_project_name')}
            label={t('gcp_project_id')}
            tooltip={t('gcp_project_id_tooltip')}
            className={formItemClass.base}
            rules={[{ required: isAIAutoSelected || true }]}
          >
            <Input
              placeholder={t('gcp_project_id')}
              onBlur={handleProjectIdBlur}
              onChange={handleProjectIdChange}
            />
          </Form.Item>
        </Popconfirm>
      </Space>
      <Space>
        {!isEditing && (
          <Form.Item
            name={getFieldName('gcp_keyfile')}
            label={t('gcp_keyfile')}
            tooltip={t('gcp_keyfile_tooltip')}
            className={formItemClass.base}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
            rules={[{ required: isAIAutoSelected || true }]}
          >
            <UploadButton accept=".json" onChange={handleKeyfileChange} />
          </Form.Item>
        )}
        {!isDLP && (
          <Form.Item
            name={getFieldName('gcp_fulfillment_auth_keyfile')}
            label={t('gcp_fulfillment_file')}
            tooltip={t('gcp_fulfillment_file_tooltip')}
            className={formItemClass.base}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
          >
            <UploadButton accept=".json" />
          </Form.Item>
        )}
      </Space>
    </>
  );
};
