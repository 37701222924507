import React from 'react';
import { Outlet } from 'react-router-dom';
import { DesignNav } from './DesignNav';

export const Design: React.FC = () => {
  return (
    <>
      <DesignNav />
      <Outlet />
    </>
  );
};
