import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { useDelete } from '../../../../../hooks/useDelete';
import { Content } from '../../../../../components/Content';
import { useAccount } from '../../../../../state/account';
import { useIntegrations } from '../../../../../state/integrations';
import { AiIntegrationParams } from '../../../../../services/api';
import { Reports } from '../../../../Analytics/Reports/types';
import { AnalyticsAirlineService } from '../../../../../services/analytics-airline';
import { UppercaseLinkBold } from '../../../../Analytics/Analyics.styles';

export const LooksList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { activeProjectId } = useAccount();
  const { aiIntegration } = useIntegrations();

  const { isLoading, data, isFetching } = useQuery<Reports[]>(
    [AnalyticsAirlineService.queryKeyLooks, activeProjectId, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      AnalyticsAirlineService.getLooks({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [AnalyticsAirlineService.queryKeyLooks],
    AnalyticsAirlineService.deleteLook,
  );

  const handleDelete = React.useCallback(
    async id => aiIntegration && (await deleteItem({ id, aiIntegration })),
    [],
  );

  return (
    <>
      <Content imgBg={false} header={false}>
        <UppercaseLinkBold to=""> {t('looks')}</UppercaseLinkBold>
        <ReportsTable
          loading={!!aiIntegration?.url && (isLoading || isDeleteLoading || isFetching)}
          reports={data || []}
          handleRemove={handleDelete}
          type="looks"
        />
      </Content>
    </>
  );
};
