import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { useWindowDimensions } from '../../../../utils/useWindowDimensions';
import { Content } from '../../../../components/Content';
import { OverlaySpinner } from '../../../../components/Spinner';
import { useAccount } from '../../../../state/account';
import { useIntegrations } from '../../../../state/integrations';
import { AiIntegrationParams } from '../../../../services/api';
import { ReportType, Reports } from '../../../Analytics/Reports/types';
import { AnalyticsAirlineService } from '../../../../services/analytics-airline';
import { BreadCrumbsText, UppercaseLink } from '../../../Analytics/Analyics.styles';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';

interface ILocation extends Location {
  state: { name: string; type: ReportType };
}

export const LookerIframe: React.FC<any> = () => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const { switchToConsoleAnalytics } = useFlags();
  const [queryKey, setQueryKey] = useState<any>();
  const [currentUrl, setCurrentUrl] = useState<string>();
  const location = useLocation() as ILocation;
  const { aiIntegration } = useIntegrations();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { height } = useWindowDimensions();
  const { name, type } = location.state;
  const { activeProjectId } = useAccount();
  const headerHeight = 185;

  const { data } = useQuery<Reports[]>(
    [queryKey, activeProjectId, aiIntegration?.metadata.PROJECT_ID, type],
    ({ signal }) => getService(type, { apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );

  useUpdateEffect(() => {
    const pathRoot = switchToConsoleAnalytics ? '/analytics' : '/old-analytics';
    navigate(type === 'dashboards' ? pathRoot : `${pathRoot}/${type}`);
  }, [activeProjectId]);

  useEffect(() => {
    if (type === 'dashboards') {
      setQueryKey(AnalyticsAirlineService.queryKeyDashboards);
    }
    if (type === 'looks') {
      setQueryKey(AnalyticsAirlineService.queryKeyLooks);
    }
    if (type === 'explore') {
      setQueryKey(AnalyticsAirlineService.queryKeyExplores);
    }
    const currentUrl = data?.find(e => e.name === name)?.url;
    currentUrl && setLoading(true);
    setCurrentUrl(currentUrl);
  }, [data, name]);

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLink to={`/old-analytics/${type}`}> {t(type)} | </UppercaseLink>
      <BreadCrumbsText>{` ${name}`} </BreadCrumbsText>
      <div style={{ position: 'relative' }}>
        <OverlaySpinner loading={loading} />
        {currentUrl && (
          <iframe
            width="100%"
            style={{ paddingTop: '20px', display: 'block' }}
            height={height - headerHeight}
            src={currentUrl}
            onLoad={() => setLoading(false)}
          />
        )}
      </div>
    </Content>
  );
};

const getService = (
  type: string,
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
) => {
  switch (type) {
    case 'dashboards':
      return AnalyticsAirlineService.getDashboards({ apiData }, signal);
    case 'looks':
      return AnalyticsAirlineService.getLooks({ apiData }, signal);
    case 'explore':
      return AnalyticsAirlineService.getExplores({ apiData }, signal);
    default:
      return AnalyticsAirlineService.getExplores({ apiData }, signal);
  }
};
