import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccount } from '../../state/account';
import { StyledNavbar } from './Navbar.styles';
import { AccountsMenu } from './AccountsMenu';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/integration.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';
import { ReactComponent as PaintIcon } from '../../assets/icons/paint-brush.svg';
import { ReactComponent as PulseIcon } from '../../assets/icons/pulse.svg';
import { ReactComponent as AiIcon } from '../../assets/icons/ai.svg';

export interface Props {
  modalToggle: () => void;
}

export const Navbar: React.FC<Props> = ({ modalToggle }) => {
  const { t } = useTranslation('pages_title');
  const { ldContext } = useAccount();
  const {
    newKnowledge,
    newDesign,
    pulse,
    analytics,
    legacyKnowledge,
    integration,
    legacyDesign,
    ai,
    switchToConsoleAnalytics,
  } = useFlags();

  return (
    <StyledNavbar.Root>
      <AccountsMenu />
      <StyledNavbar.Nav>
        {/*Wait until ldContext is updated with user data*/}
        {ldContext && !ldContext.user.anonymous ? (
          <>
            {analytics && (
              <StyledNavbar.NavItem to={switchToConsoleAnalytics ? 'analytics' : 'old-analytics'}>
                <AnalyticsIcon width={28} height={22} />
                {t('analytics')}
              </StyledNavbar.NavItem>
            )}
            {ai && (
              <StyledNavbar.NavItem to="ai">
                <AiIcon width={32} height={32} />
                {t('ai')}
              </StyledNavbar.NavItem>
            )}
            {legacyKnowledge && (
              <StyledNavbar.NavItem to="knowledge">
                <LightIcon width={26} height={26} />
                {t('knowledge')}
              </StyledNavbar.NavItem>
            )}
            {integration && (
              <StyledNavbar.NavItem to="integration">
                <IntegrationIcon width={22} height={22} />
                {t('integration')}
              </StyledNavbar.NavItem>
            )}
            {legacyDesign && (
              <StyledNavbar.NavItem to="design">
                <PaintIcon width={22} height={22} />
                {t('design')}
              </StyledNavbar.NavItem>
            )}
            {pulse && (
              <StyledNavbar.NavItem to="pulse">
                <PulseIcon width={22} height={22} /> {t('pulse')}
              </StyledNavbar.NavItem>
            )}
            {newKnowledge && (
              <StyledNavbar.TempNavItem to="new-knowledge">
                <StyledNavbar.NewLabel>NEW</StyledNavbar.NewLabel>
                <LightIcon width={22} height={22} /> {t('knowledge')}
                <p style={{ fontSize: 7 }}>({t('internal')})</p>
              </StyledNavbar.TempNavItem>
            )}
            {newDesign && (
              <StyledNavbar.NavItem to="new-design">
                <StyledNavbar.NewLabel>NEW</StyledNavbar.NewLabel>
                <PaintIcon width={22} height={22} /> {t('design')}
                <p style={{ fontSize: 7 }}>({t('internal')})</p>
              </StyledNavbar.NavItem>
            )}
          </>
        ) : null}
      </StyledNavbar.Nav>
      <StyledNavbar.HelpButton onClick={modalToggle}>
        <QuestionIcon width={22} height={22} /> {t('need_help')}
      </StyledNavbar.HelpButton>
    </StyledNavbar.Root>
  );
};
