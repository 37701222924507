import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import { IDFAgentValues, IDFAgent } from '../../pages/AI/Automation/NLUProviders/types';
import { IAiIntegrationUrl } from '../../state/integrations';
import { DFAgentType } from '../../components/DFAgentTag';

const queryKey = 'dialogflow-agents';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
  platform_edition?: DFAgentType,
  human_assist?: boolean,
): Promise<IDFAgent[]> => {
  const response = await integrationQuery(`/api/dialogflow_agents`, {
    apiData,
    params: { platform_edition, human_assist },
    signal,
  });
  return response?.dialogflow_agents;
};

const find = async (
  { id, searchParams, apiData }: { id?: string; searchParams?: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IDFAgent> => {
  const response = await integrationQuery(`/api/dialogflow_agents/${id}`, {
    apiData,
    params: { includes: searchParams },
    signal,
  });
  return response?.dialogflow_agent;
};

const create = async ({ data, apiData }: { data: IDFAgentValues } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow_agents`, {
    method: 'POST',
    data: { data: { type: 'dialogflow_agents', attributes: data } },
    apiData,
  });

const update = ({
  id,
  apiData,
  data,
}: {
  id: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IDFAgentValues>;
}): Promise<IDFAgent> => {
  return integrationQuery(`/api/dialogflow_agents/${id}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'dialogflow_agents',
        attributes: data,
      },
    },
    apiData,
  });
};

const remove = async ({ id, apiData }: { id: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow_agents/${id}`, {
    method: 'DELETE',
    apiData: apiData,
  });

export const NLUProvidersService = {
  queryKey,
  get,
  find,
  create,
  update,
  remove,
};
