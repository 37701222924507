import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { useWindowDimensions } from '../../../../utils/useWindowDimensions';
import { Content } from '../../../../components/Content';
import { Reports, ReportType } from '../types';
import { BreadCrumbsText, UppercaseLink } from '../../Analyics.styles';
import { OverlaySpinner } from '../../../../components/Spinner';
import { AnalyticsParams, AnalyticsService } from '../../../../services/analytics';
import { useIntegrations } from '../../../../state/integrations';
import { useGetItem } from '../../../../hooks/useGetItem';
import { useUpdateEffect } from '../../../../hooks/useUpdateEffect';

interface ILocation extends Location {
  state: { name: string; type: ReportType };
}

export const LookerIframe: React.FC<any> = () => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const { switchToConsoleAnalytics } = useFlags();
  const [queryKey, setQueryKey] = useState<any>();
  const [currentUrl, setCurrentUrl] = useState<string>();
  const location = useLocation() as ILocation;
  const { analytics: { url, airlineId, externalId } = {} } = useIntegrations();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { height } = useWindowDimensions();
  const { name, type } = location.state;
  const headerHeight = 185;

  const { data } = useQuery<Reports[]>(
    [queryKey, externalId, airlineId, type],
    ({ signal }) =>
      getService(
        type,
        {
          projectId: externalId,
          componentId: airlineId,
          analyticsUrl: url,
        } as AnalyticsParams,
        signal,
      ),
    { enabled: !!externalId && !!airlineId && !!url },
  );

  const { data: currentReport } = useGetItem<Reports>(
    [queryKey, externalId, currentUrl],
    ({ signal }) =>
      AnalyticsService.find({ url: currentUrl as string, analyticsUrl: url as string }, signal),
    { enabled: !!currentUrl && !!externalId },
  );

  useUpdateEffect(() => {
    const pathRoot = switchToConsoleAnalytics ? '/analytics' : '/old-analytics';
    navigate(type === 'dashboards' ? pathRoot : `${pathRoot}/${type}`);
  }, [externalId]);

  useEffect(() => {
    if (type === 'dashboards') {
      setQueryKey(AnalyticsService.queryKeyDashboards);
    }
    if (type === 'looks') {
      setQueryKey(AnalyticsService.queryKeyLooks);
    }
    if (type === 'explore') {
      setQueryKey(AnalyticsService.queryKeyExplores);
    }
    const currentUrl = data?.find(e => e.name === name)?.url;
    currentUrl && setLoading(true);
    setCurrentUrl(currentUrl);
  }, [data, name]);

  return (
    <Content imgBg={false} header={false}>
      <UppercaseLink to={`/analytics/${type}`}> {t(type)} | </UppercaseLink>
      <BreadCrumbsText>{` ${name}`} </BreadCrumbsText>
      <div style={{ position: 'relative' }}>
        <OverlaySpinner loading={loading} />
        {currentReport && (
          <iframe
            width="100%"
            style={{ paddingTop: '20px', display: 'block' }}
            height={height - headerHeight}
            src={currentReport.url}
            onLoad={() => setLoading(false)}
          />
        )}
      </div>
    </Content>
  );
};

const getService = (
  type: string,
  { projectId, componentId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
) => {
  switch (type) {
    case 'dashboards':
      return AnalyticsService.getDashboards({ projectId, componentId, analyticsUrl }, signal);
    case 'looks':
      return AnalyticsService.getLooks({ projectId, componentId, analyticsUrl }, signal);
    case 'explore':
      return AnalyticsService.getExplores({ projectId, componentId, analyticsUrl }, signal);
    default:
      return AnalyticsService.getExplores({ projectId, componentId, analyticsUrl }, signal);
  }
};
