export const hyphenateString = (string: string): string =>
  string
    .trim()
    .split(' ')
    .join('-')
    .replace(/-+/g, '-')
    .replace(/-$|^-|[^\w-]/gi, '')
    .toLowerCase();

export const stripSpecialChars = (string: string): string => string.replace(/[^\w-]/g, '');

export const extractParams = (string: string): string[] => {
  const regex = /{{([^}]+)}}/g;
  const matches = string.match(regex) || [];
  return matches.map(match => match.slice(2, -2));
};

export const stripNonAlphanumeric = (string: string): string => string.replace(/[^\w]+/g, '');

export const snakeToCapitalCase = (str: string): string => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
