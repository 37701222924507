import React from 'react';
import { Translation } from 'react-i18next';
import { ReactComponent as ChartIcon } from '../../assets/icons/chart-bar.svg';
import { ReactComponent as LightIcon } from '../../assets/icons/light.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/integration.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as PaintIcon } from '../../assets/icons/paint-brush.svg';
import { ReactComponent as AccountIcon } from '../../assets/icons/account-settings.svg';
import { ReactComponent as CogIcon } from '../../assets/icons/cog.svg';
import { ReactComponent as PulseIcon } from '../../assets/icons/pulse.svg';
import { ReactComponent as AiIcon } from '../../assets/icons/ai.svg';
import { Paths } from '../../types/paths';

const ProjectSettingsTitle = (
  <Translation ns={'pages_title'}>
    {t => (
      <>
        <CogIcon width={20} height={20} /> {t('project_settings')}
      </>
    )}
  </Translation>
);

export const titleMap = [
  {
    path: Paths.index(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <ChartIcon width={20} height={20} /> {t('dashboard')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.oldKnowledge(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <LightIcon width={20} height={20} /> {t('knowledge')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.knowledge(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <LightIcon width={20} height={20} /> {t('knowledge')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.aiIntegration(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <IntegrationIcon width={20} height={20} /> {t('integration')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.design(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <PaintIcon width={20} height={20} /> {t('design')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.designV1(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <PaintIcon width={20} height={20} /> {t('design')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.analytics(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <AnalyticsIcon width={20} height={20} /> {t('analytics')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.oldAnalytics(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <AnalyticsIcon width={20} height={20} /> {t('analytics')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.userSettings(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <AccountIcon width={20} height={20} /> {t('user_settings')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.projects(),
    title: ProjectSettingsTitle,
  },
  {
    path: Paths.users(),
    title: ProjectSettingsTitle,
  },
  {
    path: Paths.accounts(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <CogIcon width={20} height={20} /> {t('account_settings')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.pulse(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <PulseIcon width={20} height={20} /> {t('pulse')}
          </>
        )}
      </Translation>
    ),
  },
  {
    path: Paths.ai(),
    title: (
      <Translation ns={'pages_title'}>
        {t => (
          <>
            <AiIcon width={20} height={20} /> {t('ai')}
          </>
        )}
      </Translation>
    ),
  },
];
