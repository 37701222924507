import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ReportsTable } from '../../../TableComponent/table';
import { AnalyticsParams, AnalyticsService } from '../../../../../services/analytics';
import { Reports } from '../../types';
import { useDelete } from '../../../../../hooks/useDelete';
import { Content } from '../../../../../components/Content';
import { UppercaseLinkBold } from '../../../Analyics.styles';
import { useIntegrations } from '../../../../../state/integrations';

export const LooksList: React.FC = () => {
  const { t } = useTranslation('reports');
  const { analytics: { url, airlineId, externalId } = {} } = useIntegrations();

  const { isLoading, data, isFetching } = useQuery<Reports[]>(
    [AnalyticsService.queryKeyLooks, externalId, airlineId],
    ({ signal }) =>
      AnalyticsService.getLooks(
        {
          projectId: externalId,
          componentId: airlineId,
          analyticsUrl: url,
        } as AnalyticsParams,
        signal,
      ),
    { enabled: !!externalId && !!airlineId },
  );

  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [AnalyticsService.queryKeyLooks],
    AnalyticsService.deleteLook,
  );

  const handleDelete = React.useCallback(
    async reportUrl =>
      url &&
      (await deleteItem({
        reportUrl,
        analyticsUrl: url,
      })),
    [],
  );

  return (
    <>
      <Content imgBg={false} header={false}>
        <UppercaseLinkBold to=""> {t('looks')}</UppercaseLinkBold>
        <ReportsTable
          loading={!!externalId && !!airlineId && (isLoading || isDeleteLoading || isFetching)}
          reports={data || []}
          handleRemove={handleDelete}
          type="looks"
        />
      </Content>
    </>
  );
};
