import { RcFile } from 'antd/lib/upload/interface';
import { IAutomationProfile, IConfigAttribute, IVAConfigValues } from '../pages/Design/types';
import { initialColours } from '../pages/Design/VirtualAssistant';
import { getBase64 } from './files';

export const isAssignedProfile = (ids: React.Key[], profiles: IAutomationProfile[]): boolean =>
  profiles
    .filter(item => item.virtual_agent_config)
    .some(profile => ids.includes(profile.id.toString()));

export const getConfigValues = (
  config: Record<string, any>,
  initial: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};
  Object.keys(initial).forEach(key => {
    result[key] = config[key] || initial[key];
  });
  return result;
};

export const getHexForColours = (config: Record<string, any>): Record<string, any> => {
  const result: Record<string, any> = {};
  Object.keys(initialColours).forEach(key => {
    if (config[key]) {
      result[key] = typeof config[key] !== 'string' ? config[key].toHexString() : config[key];
    }
  });
  return result;
};

export const getConfigAttribute = async (
  values: IVAConfigValues,
  avatarUrl?: string,
): Promise<IConfigAttribute> => {
  const theme = { ...values.config, ...getHexForColours(values.config) };
  const url = values.avatar ? await getBase64(values.avatar[0].originFileObj as RcFile) : avatarUrl;
  return {
    avatar_url: url,
    theme: JSON.stringify(theme),
    menu_items: JSON.stringify(values.menu_items),
  };
};
