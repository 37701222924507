import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { AiIntegrationParams, integrationQuery } from '../api';
import {
  IKnowledgeBaseDocument,
  IKnowledgeBaseDocumentValues,
} from '../../pages/AI/Configuration/KnowledgeBases/types';
import { IAiIntegrationUrl } from '../../state/integrations';

const queryKey = 'knowledge-base-document';

const get = async (
  { apiData }: AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
  id?: string,
): Promise<IKnowledgeBaseDocument[]> => {
  const response = await integrationQuery(`/api/dialogflow/knowledge_bases/${id}/documents`, {
    apiData,
    signal,
  });
  return response?.['dialogflow/knowledge_base_documents'];
};

const remove = async ({
  id,
  docId,
  apiData,
}: { id: string; docId: string } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/knowledge_bases/${id}/documents/${docId}`, {
    method: 'DELETE',
    apiData: apiData,
  });

const create = async ({
  id,
  data,
  apiData,
}: { id: string; data: IKnowledgeBaseDocument } & AiIntegrationParams) =>
  await integrationQuery(`/api/dialogflow/knowledge_bases/${id}/documents`, {
    method: 'POST',
    data: { data: { type: 'knowledge_base_documents', attributes: data } },
    apiData,
  });

const find = async (
  { id, docId, apiData }: { id: string; docId: string } & AiIntegrationParams,
  signal: QueryFunctionContext['signal'],
): Promise<IKnowledgeBaseDocument> => {
  const response = await integrationQuery(
    `/api/dialogflow/knowledge_bases/${id}/documents/${docId}`,
    {
      apiData,
      signal,
    },
  );
  return response?.['dialogflow/knowledge_base_document'];
};

const update = ({
  id,
  docId,
  apiData,
  data,
}: {
  id: string;
  docId: string;
  apiData: IAiIntegrationUrl;
  data: Partial<IKnowledgeBaseDocumentValues>;
}): Promise<IKnowledgeBaseDocument> => {
  return integrationQuery(`/api/dialogflow/knowledge_bases/${id}/documents/${docId}`, {
    method: 'PUT',
    data: {
      data: {
        type: 'knowledge_base_documents',
        attributes: data,
      },
    },
    apiData,
  });
};

export const KnowledgeBaseDocumentService = {
  queryKey,
  get,
  remove,
  create,
  find,
  update,
};
