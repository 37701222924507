import { App, Form, Input, Modal } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/copy.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/times.svg';
import { ActionButton } from '../../../../components/ActionButton';
import { IAiIntegrationUrl, useIntegrations } from '../../../../state/integrations';
import { useGetItem } from '../../../../hooks/useGetItem';
import { IVAConfig } from '../../types';
import { DesignService } from '../../../../services/design';
import { validateMessages } from '../../../../utils/validation';
import { useCreate } from '../../../../hooks/useCreate';
import { unexpectedError } from '../../../../utils/notification';
import { formatApiErrors, getFormErrors } from '../../../../utils/errors';

type Props = {
  id: string;
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
};

export const DuplicateModal: React.FC<Props> = ({ id, isVisible, setIsVisible }) => {
  const { t } = useTranslation('design');
  const { aiIntegration } = useIntegrations();
  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const { data } = useGetItem<IVAConfig>(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) => DesignService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && isVisible },
  );
  const { mutate: createItem } = useCreate(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    DesignService.create,
  );

  const handleCancel = () => setIsVisible(false);
  const handleOk = () => form.submit();

  async function onFinish(values: { name: string }) {
    if (data) {
      const payload = {
        name: values.name,
        config: data.config,
        menu_items: data.menu_items,
      };
      await createItem(
        { apiData: aiIntegration, payload, avatarUrl: data?.avatar_url },
        {
          onSuccess: response => {
            setIsVisible(false);
            notification['success']({
              message: (
                <Trans t={t}>
                  {t('va_created_notify', { name: response.virtual_agent_config.name })}
                </Trans>
              ),
            });
          },
          onError: err => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => form.setFields(getFormErrors(formattedErrors)), 100);
            } else notification['error'](unexpectedError);
          },
        },
      );
    }
  }

  const initialValues = {
    name: `${data?.name}_copy`,
  };

  return (
    <Modal
      width={408}
      title={
        <>
          <CopyIcon width={16} height={16} /> {t('duplicate_v_a')}
        </>
      }
      centered
      open={isVisible}
      closeIcon={<CloseIcon width={16} height={16} />}
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={handleCancel} />,
        <ActionButton type="duplicate" key="duplicate" onClick={handleOk} />,
      ]}
      onCancel={handleCancel}
    >
      {!!data && (
        <Form
          initialValues={initialValues}
          onFinish={onFinish}
          form={form}
          layout="vertical"
          validateMessages={validateMessages}
          validateTrigger="onBlur"
        >
          <Form.Item name="name" label={t('Name')} rules={[{ required: true }]}>
            <Input placeholder={t('virtual_assistant_name')} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
