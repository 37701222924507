import { QueryFunctionContext } from '@tanstack/query-core/src/types';
import { fAReportsQuery, analyticsQuery } from './api';
import { Reports } from '../pages/Analytics/Reports/types';
import { ApiResponse } from './types';

export interface IAnalyticsComponent {
  id: number;
  project_id: number;
  name: 'airline' | 'pulse';
  metadata: any;
  bi_provider_folder_sid: number;
  created_at: string;
  updated_at: string;
}
export type AnalyticsParams = { projectId: string; componentId: number; analyticsUrl: string };

type DeleteProps = {
  reportUrl: string;
  analyticsUrl: string;
};

const queryKeyDashboards = 'reportDashboards';
const queryKeyExplores = 'reportExplores';
const queryKeyLooks = 'reportLooks';
const queryKeyFAReports = 'fAReports';
const queryKeyAnalyticsComponents = 'analyticsComponents';

const find = async (
  { url, analyticsUrl }: { url: string; analyticsUrl: string },
  signal: QueryFunctionContext['signal'],
): Promise<Reports> => await analyticsQuery(url, { analyticsUrl, signal });

const getDashboards = async (
  { projectId, componentId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
) => {
  const response = await analyticsQuery(
    `/api/projects/${projectId}/components/${componentId}/looker/embed/dashboards`,
    { analyticsUrl, signal },
  );
  return response || [];
};

const deleteDashboard = async ({ reportUrl, analyticsUrl }: DeleteProps): Promise<ApiResponse> =>
  await analyticsQuery(reportUrl, {
    method: 'DELETE',
    analyticsUrl,
  });

const getExplores = async (
  { projectId, componentId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
): Promise<Reports[]> => {
  const response = await analyticsQuery(
    `/api/projects/${projectId}/components/${componentId}/looker/embed/explores`,
    { analyticsUrl, signal },
  );
  return (
    response.map((el: Reports) => ({
      id: el.name, // we need id to render element's list
      name: el.name,
      url: el.url,
    })) || []
  );
};

const getLooks = async (
  { projectId, componentId, analyticsUrl }: AnalyticsParams,
  signal: QueryFunctionContext['signal'],
): Promise<Reports[]> => {
  const response = await analyticsQuery(
    `/api/projects/${projectId}/components/${componentId}/looker/embed/looks`,
    { analyticsUrl, signal },
  );
  return response || [];
};

const deleteLook = async ({ reportUrl, analyticsUrl }: DeleteProps): Promise<ApiResponse> =>
  await analyticsQuery(reportUrl, {
    method: 'DELETE',
    analyticsUrl,
  });

const getFAReports = async (signal: QueryFunctionContext['signal']): Promise<Reports[]> => {
  const response = await fAReportsQuery({ signal });
  return response.Reports || [];
};

const getAnalyticsComponents = async ({
  queryKey,
  signal,
}: QueryFunctionContext): Promise<IAnalyticsComponent[]> => {
  const response = await analyticsQuery(`/api/projects/${queryKey[1]}/components`, {
    analyticsUrl: queryKey[2] as string,
    signal,
  });
  return response.components || [];
};

export const AnalyticsService = {
  queryKeyDashboards,
  queryKeyExplores,
  queryKeyLooks,
  queryKeyFAReports,
  queryKeyAnalyticsComponents,
  find,
  getDashboards,
  deleteDashboard,
  getExplores,
  getLooks,
  deleteLook,
  getFAReports,
  getAnalyticsComponents,
};
