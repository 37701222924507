import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteConversationsForm, IDeleteConversationsValues } from './DeleteConversationsForm';
import { Content } from '../../../../components/Content';
import { ActionButton } from '../../../../components/ActionButton';
import { useDelete } from '../../../../hooks/useDelete';
import { DeleteConversationsService } from '../../../../services/ai/delete-conversations';
import { useIntegrations } from '../../../../state/integrations';
import { useModal } from '../../../../hooks/useModal';

export const DeleteConversations = () => {
  const { aiIntegration } = useIntegrations();
  const { visible, openModal, closeModal } = useModal(false);
  const [deletionError, setDeletionError] = useState<boolean>(false);
  const { t } = useTranslation('ai');

  const { mutate: deleteItem, isLoading: isDeleteLoading } = useDelete(
    [DeleteConversationsService.queryKey],
    DeleteConversationsService.remove,
  );

  const onSubmit = async (values: IDeleteConversationsValues): Promise<void> => {
    delete values.attribute_type;
    return (
      aiIntegration &&
      deleteItem(
        { data: values, apiData: aiIntegration },
        {
          onSuccess: () => {
            openModal();
          },
          onError: () => {
            setDeletionError(true);
            openModal();
          },
        },
      )
    );
  };

  const initialValues = {
    attribute_type: 'session_uuid',
  };

  return (
    <Content imgBg={false}>
      <DeleteConversationsForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitButton={<ActionButton type="delete" htmlType="submit" loading={isDeleteLoading} />}
      />
      <Modal
        width={408}
        centered
        title={deletionError ? t('deletion_request_failed') : t('deletion_request_completed')}
        open={visible}
        onCancel={closeModal}
        footer={[<ActionButton type="cancel" key="cancel" onClick={closeModal} />]}
      >
        {deletionError ? t('contact_support') : t('deletion_success_description')}
      </Modal>
    </Content>
  );
};
