import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Content } from '../../../../components/Content';
import { StyledCard, CardInfo } from '../../../AI/AI.styles';
import { Paths } from '../../../../types/paths';
import { ViewLink } from '../../../../components/ViewLink';

export const ConfigurationsList: React.FC = () => {
  const { t } = useTranslation('ai');
  const { switchToConsoleAnalytics } = useFlags();

  return (
    <Content imgBg={false}>
      <Row gutter={16}>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('ext_webhook_req')} bordered={false}>
            <CardInfo>{t('ext_webhook_req_desc')}</CardInfo>
            <ViewLink
              to={switchToConsoleAnalytics ? Paths.analyticWebhook() : Paths.oldAnalyticWebhook()}
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('dlp_template')} bordered={false}>
            <CardInfo>{t('dlp_template_desc')}</CardInfo>
            <ViewLink
              to={switchToConsoleAnalytics ? Paths.analyticDLP() : Paths.oldAnalyticDLP()}
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('data_retention_policy')} bordered={false}>
            <CardInfo>{t('data_retention_policy_desc')}</CardInfo>
            <ViewLink
              to={
                switchToConsoleAnalytics
                  ? Paths.analyticDataRetentionPolicy()
                  : Paths.oldAnalyticDataRetentionPolicy()
              }
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} lg={8} xxl={6}>
          <StyledCard title={t('delete_conversations')} bordered={false}>
            <CardInfo>{t('delete_conversations_description')}</CardInfo>
            <ViewLink
              to={
                switchToConsoleAnalytics
                  ? Paths.analyticsDeleteConversations()
                  : Paths.oldAnalyticsDeleteConversations()
              }
            />
          </StyledCard>
        </Col>
      </Row>
    </Content>
  );
};
