import { Translation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Paths } from '../../types/paths';
import { Ai } from './index';
import { Automation } from './Automation';
import { AutomationList } from './Automation/AutomationList';
import { ContextsList } from './Automation/Contexts/ContextsList';
import { ContextNew } from './Automation/Contexts/ContextNew';
import { ContextEdit } from './Automation/Contexts/ContextEdit';
import { NLUProvidersList } from './Automation/NLUProviders/NLUProvidersList';
import { NLUProvidersNew } from './Automation/NLUProviders/NLUProvidersNew';
import { NLUProvidersEdit } from './Automation/NLUProviders/NLUProvidersEdit';
import { TextToSpeechList } from './Automation/TextToSpeech/TextToSpeechList';
import { TextToSpeechNew } from './Automation/TextToSpeech/TextToSpeechNew';
import { TextToSpeechEdit } from './Automation/TextToSpeech/TextToSpeechEdit';
import { AutomatedResponseTemplatesList } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesList';
import { AutomatedResponseTemplatesNew } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesNew';
import { AutomatedResponseTemplatesEdit } from './Automation/AutomatedResponseTemplates/AutomatedResponseTemplatesEdit';
import { ConversationProfilesList } from './Automation/ConversationProfiles/ConversationProfilesList';
import { ConversationProfilesNew } from './Automation/ConversationProfiles/ConversationProfilesNew';
import { ConversationProfilesEdit } from './Automation/ConversationProfiles/ConversationProfilesEdit';
import { AutomationProfilesList } from './Automation/AutomationProfiles/AutomationProfilesList';
import { AutomationProfilesNew } from './Automation/AutomationProfiles/AutomationProfilesNew';
import { AutomationProfilesEdit } from './Automation/AutomationProfiles/AutomationProfilesEdit';
import { AgenticModelsList } from './Automation/AgenticModels/AgenticModelsList';
import { AgenticModelsNew } from './Automation/AgenticModels/AgenticModelsNew';
import { AgenticModelsEdit } from './Automation/AgenticModels/AgenticModelsEdit';
import { Channels } from './Channels';
import { ChannelsList } from './Channels/ChannelsList';
import { ChannelList } from './Channels/Channel/ChannelList';
import { ChannelNew } from './Channels/Channel/ChannelNew';
import { ChannelEdit } from './Channels/Channel/ChannelEdit';
import { Configuration } from './Configuration';
import { ConfigurationList } from './Configuration/ConfigurationList';
import { FallbackBehaviourList } from './Configuration/FallbackBehaviour/FallbackBehaviourList';
import { FallbackBehaviourNew } from './Configuration/FallbackBehaviour/FallbackBehaviourNew';
import { FallbackBehaviourEdit } from './Configuration/FallbackBehaviour/FallbackBehaviourEdit';
import { KnowledgeBasesList } from './Configuration/KnowledgeBases/KnowledgeBasesList';
import { KnowledgeBasesNew } from './Configuration/KnowledgeBases/KnowledgeBasesNew';
import { KnowledgeBasesEdit } from './Configuration/KnowledgeBases/KnowledgeBasesEdit';
import { KnowledgeBaseDocumentList } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentList';
import { KnowledgeBaseDocumentNew } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentNew';
import { KnowledgeBaseDocumentEdit } from './Configuration/KnowledgeBases/KnowledgeBaseDocumentEdit';
import { Routing } from './Routing';
import { RoutingList } from './Routing/RoutingList';
import { ExternalEndpointsList } from './Routing/ExternalEndpoints/ExternalEnpointsList';
import { ExternalEndpointsNew } from './Routing/ExternalEndpoints/ExternalEndpointsNew';
import { ExternalEndpointsEdit } from './Routing/ExternalEndpoints/ExternalEndpointsEdit';
import { TeamsList } from './Routing/Teams/TeamsList';
import { TeamsNew } from './Routing/Teams/TeamsNew';
import { TeamsEdit } from './Routing/Teams/TeamsEdit';
import { RoutersList } from './Routing/Routers/RoutersList';
import { RoutersNew } from './Routing/Routers/RoutersNew';
import { RoutersEdit } from './Routing/Routers/RoutersEdit';

export const aiRoutes = () => ({
  path: Paths.ai(),
  element: <Ai />,
  breadcrumb: null,
  children: [
    { index: true, element: <Navigate replace to="automation" />, breadcrumb: null },
    {
      path: Paths.aiAutomation(),
      element: <Automation />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('automation')}</Translation>,
      children: [
        {
          index: true,
          element: <AutomationList />,
        },
        {
          path: Paths.aiAutoRespTemplates(),
          element: <AutomatedResponseTemplatesList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('auto_resp_templates')}</Translation>,
        },
        {
          path: Paths.aiAutoRespTemplatesCreate(),
          element: <AutomatedResponseTemplatesNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiAutoRespTemplatesEdit(),
          element: <AutomatedResponseTemplatesEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiTextToSpeech(),
          element: <TextToSpeechList />,
          breadcrumb: () => (
            <Translation ns={'ai'}>{t => t('text_to_speech_profiles')}</Translation>
          ),
        },
        {
          path: Paths.aiTextToSpeechCreate(),
          element: <TextToSpeechNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiTextToSpeechEdit(),
          element: <TextToSpeechEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiNLUProviders(),
          element: <NLUProvidersList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('nlu_providers')}</Translation>,
        },
        {
          path: Paths.aiNLUProvidersCreate(),
          element: <NLUProvidersNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiNLUProvidersEdit(),
          element: <NLUProvidersEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiContexts(),
          element: <ContextsList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('contexts')}</Translation>,
        },
        {
          path: Paths.aiContextCreate(),
          element: <ContextNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiContextEdit(),
          element: <ContextEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiConversationProfiles(),
          element: <ConversationProfilesList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('conversation_profiles')}</Translation>,
        },
        {
          path: Paths.aiConversationProfilesCreate(),
          element: <ConversationProfilesNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiConversationProfilesEdit(),
          element: <ConversationProfilesEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiAutomationProfiles(),
          element: <AutomationProfilesList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('automation_profiles')}</Translation>,
        },
        {
          path: Paths.aiAutomationProfilesCreate(),
          element: <AutomationProfilesNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiAutomationProfilesEdit(),
          element: <AutomationProfilesEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiAgenticModels(),
          element: <AgenticModelsList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('agentic_models')}</Translation>,
        },
        {
          path: Paths.aiAgenticModelsCreate(),
          element: <AgenticModelsNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiAgenticModelsEdit(),
          element: <AgenticModelsEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
      ],
    },
    {
      path: Paths.aiChannels(),
      element: <Channels />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('channels')}</Translation>,
      children: [
        {
          index: true,
          element: <ChannelsList />,
        },
        {
          path: Paths.aiChannelList(),
          element: <ChannelList />,
        },
        {
          path: Paths.aiChannelCreate(),
          element: <ChannelNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiChannelEdit(),
          element: <ChannelEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
      ],
    },
    {
      path: Paths.aiConfiguration(),
      element: <Configuration />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('configuration')}</Translation>,
      children: [
        {
          index: true,
          element: <ConfigurationList />,
        },
        {
          path: Paths.aiFallbackBehaviour(),
          element: <FallbackBehaviourList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('fallback_behav')}</Translation>,
        },
        {
          path: Paths.aiFallbackBehaviourCreate(),
          element: <FallbackBehaviourNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiFallbackBehaviourEdit(),
          element: <FallbackBehaviourEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiKnowledgeBases(),
          element: <KnowledgeBasesList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('knowledge_bases')}</Translation>,
        },
        {
          path: Paths.aiKnowledgeBasesCreate(),
          element: <KnowledgeBasesNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiKnowledgeBasesEdit(),
          element: <KnowledgeBasesEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiKnowledgeBaseDocument(),
          breadcrumb: null,
        },
        {
          path: Paths.aiKnowledgeBaseDocumentList(),
          element: <KnowledgeBaseDocumentList />,
        },
        {
          path: Paths.aiKnowledgeBaseDocumentNew(),
          element: <KnowledgeBaseDocumentNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create_document')}</Translation>,
        },
        {
          path: Paths.aiKnowledgeBaseDocumentEdit(),
          element: <KnowledgeBaseDocumentEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit_document')}</Translation>,
        },
      ],
    },
    {
      path: Paths.aiRouting(),
      element: <Routing />,
      breadcrumb: () => <Translation ns={'ai'}>{t => t('routing')}</Translation>,
      children: [
        {
          index: true,
          element: <RoutingList />,
        },
        {
          path: Paths.aiExternalEndpoints(),
          element: <ExternalEndpointsList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('external_endpoints')}</Translation>,
        },
        {
          path: Paths.aiExternalEndpointsCreate(),
          element: <ExternalEndpointsNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiExternalEndpointsEdit(),
          element: <ExternalEndpointsEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiTeams(),
          element: <TeamsList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('teams')}</Translation>,
        },
        {
          path: Paths.aiTeamsCreate(),
          element: <TeamsNew />,
          breadcrumb: () => <Translation>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiTeamsEdit(),
          element: <TeamsEdit />,
          breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
        },
        {
          path: Paths.aiRouters(),
          element: <RoutersList />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('routers')}</Translation>,
        },
        {
          path: Paths.aiRoutersCreate(),
          element: <RoutersNew />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('create')}</Translation>,
        },
        {
          path: Paths.aiRoutersEdit(),
          element: <RoutersEdit />,
          breadcrumb: () => <Translation ns={'ai'}>{t => t('edit')}</Translation>,
        },
      ],
    },
  ],
});
