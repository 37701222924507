import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/es/table';
import { DesignService } from '../../../services/design';
import { useIntegrations } from '../../../state/integrations';
import { Paths } from '../../../types/paths';
import { IVAConfig } from '../types';
import { ActionButton } from '../../../components/ActionButton';
import { Content } from '../../../components/Content';
import { DataTable } from '../../../components/DataTable';
import { AutomationProfiles } from './AutomationProfiles';
import { DeleteConfirmation } from '../../../components/DeleteConfirmation';
import { DuplicateItem } from './DuplicateItem';
import { NoWrap } from '../../../components/DataTable/DataTable.styles';
import { useDelete } from '../../../hooks/useDelete';
import { formatDate } from '../../../utils/date';
import { useDateColumnFilter } from '../../../hooks/useDateColumnFilter';
import { VirtualAssistantNew } from '../VirtualAssistantNew';
import { AiIntegrationParams } from '../../../services/api';

export const ConfigsList: React.FC = () => {
  const { t } = useTranslation('design');
  const navigate = useNavigate();
  const { aiIntegration } = useIntegrations();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { data, isLoading } = useQuery<IVAConfig[], string>(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) => DesignService.get({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url },
  );
  const { mutate: deleteItem } = useDelete([DesignService.queryKey], DesignService.remove);
  const [searchDate, setSearchDate] = useState<string[]>([]);

  const onCellHandler = ({ id }: IVAConfig) => ({
    onClick: () => navigate(Paths.designEdit({ id })),
  });

  const handleDelete = React.useCallback(
    async id => aiIntegration && (await deleteItem({ id, apiData: aiIntegration })),
    [aiIntegration],
  );

  const columns: ColumnProps<IVAConfig>[] = [
    {
      title: t('name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      onCell: onCellHandler,
      fixed: 'left',
    },
    {
      title: t('automation_profile'),
      dataIndex: 'dial_plans',
      render: data => <AutomationProfiles profiles={data} />,
      onCell: onCellHandler,
      width: '32%',
    },
    {
      title: 'Date Modified',
      dataIndex: 'updated_at',
      width: '18%',
      ...useDateColumnFilter('updated_at', searchDate, setSearchDate),
      render: date => (
        <NoWrap>
          {searchDate.length ? searchDate.find(item => item == formatDate(date)) : formatDate(date)}
        </NoWrap>
      ),
    },
    {
      key: 'actions',
      width: 94,
      render: ({ id, name }) => (
        <>
          <DuplicateItem id={id} />
          <DeleteConfirmation
            id={id}
            title={t('delete_v_a')}
            onActionDelete={handleDelete}
            name={name}
          />
        </>
      ),
      className: 'actions',
      fixed: 'right',
    },
  ];

  const ButtonCreate = <ActionButton type="create" onClick={() => setIsVisible(true)} />;

  return (
    <Content imgBg={false} Actions={[ButtonCreate]}>
      <DataTable dataSource={data} columns={columns} loading={isLoading} />
      <VirtualAssistantNew isVisible={isVisible} setIsVisible={setIsVisible} />
    </Content>
  );
};
