import React, { useEffect, useState } from 'react';
import { App, Form, Input, Modal, Select } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/times.svg';
import { ActionButton } from '../../../components/ActionButton';
import { validateMessages } from '../../../utils/validation';
import { IAutomationProfile, IVAConfigPayload, IVAConfigValues } from '../types';
import { formatApiErrors, getFormErrors } from '../../../utils/errors';
import { unexpectedError } from '../../../utils/notification';
import { initialColours, initialLayout } from '../VirtualAssistant';
import { useCreate } from '../../../hooks/useCreate';
import { DesignService } from '../../../services/design';
import { useIntegrations } from '../../../state/integrations';
import { Paths } from '../../../types/paths';
import { AiIntegrationParams } from '../../../services/api';
import { ReactComponent as ExclamationCircle } from '../../../assets/icons/exclamation-circle.svg';
import { StyledWarning } from '../../../components/ModalWarning';
import { isAssignedProfile } from '../../../utils/design';

const { Option } = Select;

interface Props {
  isVisible: boolean;
  setIsVisible: (arg: boolean) => void;
}

export const VirtualAssistantNew: React.FC<Props> = ({ isVisible, setIsVisible }) => {
  const { t } = useTranslation('design');
  const [form] = Form.useForm();
  const { aiIntegration } = useIntegrations();
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const { data: profiles } = useQuery<IAutomationProfile[], string>(
    [DesignService.queryKeyProfiles, aiIntegration?.metadata.PROJECT_ID],
    ({ signal }) =>
      DesignService.getProfiles({ apiData: aiIntegration } as AiIntegrationParams, signal),
    { enabled: !!aiIntegration?.url && isVisible },
  );
  const { mutate: createItem } = useCreate(
    [DesignService.queryKey, aiIntegration?.metadata.PROJECT_ID],
    DesignService.create,
  );
  const dialPlanIds = Form.useWatch('dial_plan_ids', form) || [];

  useEffect(() => {
    if (isAssignedProfile(dialPlanIds, profiles || [])) {
      setIsWarning(true);
    } else {
      setIsWarning(false);
    }
  }, [dialPlanIds, profiles]);

  const handleCreate = () => form.submit();

  async function onFinish(values: Partial<IVAConfigPayload>) {
    const payload = {
      name: values.name,
      dial_plan_ids: JSON.stringify(values.dial_plan_ids),
      config: JSON.stringify({
        ...initialColours,
        ...initialLayout,
      }),
    };
    await createItem(
      { apiData: aiIntegration, payload },
      {
        onSuccess: data => {
          setIsVisible(false);
          notification['success']({
            message: (
              <Trans t={t}>
                {t('va_created_notify', { name: data.virtual_agent_config.name })}
              </Trans>
            ),
          });
          navigate(Paths.designEdit({ id: data.virtual_agent_config.id }));
        },
        onError: err => {
          if (err.response.status === 400) {
            const formattedErrors = formatApiErrors(Object.assign({}, ...err.response.data.errors));
            setTimeout(() => form.setFields(getFormErrors(formattedErrors)), 100);
          } else notification['error'](unexpectedError);
        },
      },
    );
  }

  const initialValues: Partial<IVAConfigValues> = {
    name: '',
    dial_plan_ids: [],
  };

  return (
    <Modal
      width={408}
      title={
        <>
          <PlusIcon width={16} height={16} /> {t('va_create')}
        </>
      }
      centered
      open={isVisible}
      closeIcon={<CloseIcon width={16} height={16} />}
      footer={[
        <ActionButton type="cancel" key="cancel" onClick={() => setIsVisible(false)} />,
        <ActionButton type="create" key="create" onClick={handleCreate} />,
      ]}
    >
      <Form
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        validateMessages={validateMessages}
        validateTrigger="onBlur"
      >
        <Form.Item name="name" label={t('name')} rules={[{ required: true }]}>
          <Input placeholder={t('virtual_assistant_name')} />
        </Form.Item>
        <Form.Item
          name="dial_plan_ids"
          label={t('automation_profile')}
          help={
            isWarning && (
              <StyledWarning>
                <ExclamationCircle width={12} /> {t('reassign_warning')}
              </StyledWarning>
            )
          }
        >
          <Select
            mode="multiple"
            suffixIcon={null}
            placeholder={t('automation_profile_placeholder')}
          >
            {profiles?.map(item => (
              <Option key={item.id.toString()} value={item.id.toString()}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
