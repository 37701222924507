import React from 'react';
import { Translation } from 'react-i18next';
import { Paths } from '../../types/paths';
import { Design } from './index';
import { ConfigsList } from './ConfigsList';
import { VirtualAssistant } from './VirtualAssistant';

export const designRoutes = () => ({
  path: Paths.design(),
  element: <Design />,
  breadcrumb: () => <Translation ns={'design'}>{t => t('design')}</Translation>,
  children: [
    { index: true, element: <ConfigsList /> },
    {
      path: Paths.designEdit(),
      element: <VirtualAssistant />,
      breadcrumb: () => <Translation>{t => t('edit')}</Translation>,
    },
  ],
});
