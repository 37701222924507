import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAccount } from './account';
import { ProjectsService } from '../services/projects';
import { AnalyticsService, IAnalyticsComponent } from '../services/analytics';
import { useAuth } from './auth';

export enum IntegrationType {
  ai,
  oldKnowledge,
  billing,
  knowledge,
  pulse,
  analytics,
}

type KnowledgeMeta = { VALink: string; JavascriptPlugin: string; FAQLink: string };
type UrlMeta = {
  AUTH_PROJECT_LIST: string;
  AUTH_TOKEN: string;
  PROJECT_ID: string;
};

export interface IProductIntegration {
  id: number;
  name: string;
  external_id: string;
  metadata: KnowledgeMeta | any;
  type: IntegrationType;
  urls: IIntegrationUrls[] | IAiIntegrationUrl[];
}
export interface IIntegrationUrls {
  name: string;
  url: string;
  metadata: any;
}
export interface IAiIntegrationUrl {
  name: string;
  url: string;
  metadata: UrlMeta;
}
export interface IChatUrl {
  name: string;
  url: string;
  metadata: {
    account_id: number;
    project_id: number | string;
  };
}
interface IKnowledgeDesign {
  id: number;
  name: string;
  external_id: string;
  metadata: KnowledgeMeta;
  type: IntegrationType;
  urls: IIntegrationUrls;
}
export interface IKnowledgeUrl {
  externalId: string;
  url: string;
  metadata: UrlMeta;
  knowledgeItemMetadata: { is_approval_flow_enabled?: boolean };
}
interface IPulseData {
  id: number;
  name: string;
  external_id: string;
  metadata: {
    telcoAccount: string;
    telcoAuthToken: string;
  };
  type: IntegrationType;
  urls: IIntegrationUrls[];
}
interface IAnalyticsData {
  externalId: string;
  url?: string;
  airlineId?: number;
  pulseId?: number;
}
interface IIntegrationContext {
  aiIntegrationClient?: IIntegrationUrls;
  aiIntegration?: IAiIntegrationUrl;
  chat?: IChatUrl;
  design?: IKnowledgeDesign[];
  oldKnowledge?: IKnowledgeDesign[];
  knowledge?: IKnowledgeUrl;
  pulse?: IPulseData;
  analytics?: IAnalyticsData;
  isFetching?: boolean;
}

export const IntegrationsContext = React.createContext<IIntegrationContext>(
  {} as IIntegrationContext,
);

const updateIntegrationState = (
  data: IProductIntegration[],
  setState: React.Dispatch<React.SetStateAction<IIntegrationContext>>,
): void => {
  data.forEach(item => {
    switch (item.type) {
      case IntegrationType.ai:
        setState(prev => ({
          ...prev,
          aiIntegrationClient: item.urls.find(url => url.name === 'manager_client'),
          aiIntegration: item.urls.find(url => url.name === 'external_api'),
          chat: item.urls.find(url => url.name === 'chat_script_api'),
        }));
        break;
      case IntegrationType.oldKnowledge:
        setState(prev => ({
          ...prev,
          oldKnowledge: getItemsByUrlName([item], 'main_api'),
          design: getItemsByUrlName([item], 'design_api'),
        }));
        break;
      case IntegrationType.knowledge:
        const mainData = item.urls.find(url => url.name === 'external_api');
        if (mainData) {
          setState(prev => ({
            ...prev,
            knowledge: {
              externalId: item.external_id,
              url: mainData.url,
              metadata: mainData.metadata,
              knowledgeItemMetadata: item.metadata,
            },
          }));
        }
        break;
      case IntegrationType.pulse:
        setState(prev => ({
          ...prev,
          pulse: item as IPulseData,
        }));
        break;
      case IntegrationType.analytics:
        const data = item.urls.find(url => url.name === 'external_api');
        if (data) {
          setState(prev => ({
            ...prev,
            analytics: {
              ...prev.analytics,
              url: data.url,
              externalId: item.external_id,
            },
          }));
        }
        break;
      default:
        break;
    }
  });
};

const updateComponentsState = (
  componentsData: IAnalyticsComponent[],
  setState: React.Dispatch<React.SetStateAction<IIntegrationContext>>,
): void => {
  componentsData.forEach(component => {
    switch (component.name) {
      case 'airline':
        setState(prev => ({
          ...prev,
          analytics: {
            ...prev.analytics,
            airlineId: component.id,
            externalId: prev.analytics?.externalId as string,
          },
        }));
        break;
      case 'pulse':
        setState(prev => ({
          ...prev,
          analytics: {
            ...prev.analytics,
            pulseId: component.id,
            externalId: prev.analytics?.externalId as string,
          },
        }));
        break;
      default:
        break;
    }
  });
};
export const IntegrationsProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { activeAccountId, activeProjectId } = useAccount();
  const { authToken } = useAuth();
  const initialState = {
    aiIntegrationClient: undefined,
    aiIntegration: undefined,
    design: undefined,
    oldKnowledge: undefined,
    knowledge: undefined,
    pulse: undefined,
    analytics: undefined,
  };
  const [state, setState] = useState<IIntegrationContext>(initialState);
  const { i18n } = useTranslation();
  const { switchToConsoleAnalytics } = useFlags();

  const { data, isFetching, refetch } = useQuery<IProductIntegration[], string>(
    [ProjectsService.queryKeyIntegrations, activeAccountId, activeProjectId, i18n.language],
    ProjectsService.getIntegrations,
    { enabled: !!activeAccountId && !!activeProjectId },
  );

  const { data: componentsData } = useQuery<IAnalyticsComponent[], string>(
    [
      AnalyticsService.queryKeyAnalyticsComponents,
      state.analytics?.externalId,
      state.analytics?.url,
      switchToConsoleAnalytics,
    ],
    AnalyticsService.getAnalyticsComponents,
    {
      enabled:
        !!activeAccountId &&
        !!state.analytics?.externalId &&
        !!switchToConsoleAnalytics &&
        !!state.analytics?.url,
    },
  );

  useEffect(() => {
    if (!!activeAccountId && !!activeProjectId) {
      refetch();
    }
  }, [authToken]);

  useEffect(() => {
    setState(initialState);
    if (data?.length) {
      updateIntegrationState(data, setState);
    }
  }, [data]);

  useEffect(() => {
    if (componentsData?.length) {
      updateComponentsState(componentsData, setState);
    }
  }, [data, componentsData]);

  const value = useMemo(
    () => ({
      aiIntegrationClient: state.aiIntegrationClient,
      aiIntegration: state.aiIntegration,
      chat: state.chat,
      oldKnowledge: state.oldKnowledge,
      design: state.design,
      knowledge: state.knowledge,
      pulse: state.pulse,
      analytics: state.analytics,
      isFetching,
    }),
    [state, isFetching],
  );

  return <IntegrationsContext.Provider value={value}>{children}</IntegrationsContext.Provider>;
};

export function useIntegrations(): IIntegrationContext {
  const context = useContext(IntegrationsContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

const getItemsByUrlName = (
  knowledgeItems: IProductIntegration[],
  name: 'design_api' | 'main_api',
): IKnowledgeDesign[] => {
  return knowledgeItems.reduce((result: IKnowledgeDesign[], option) => {
    const designUrl = option.urls.find(url => url.name === name);
    if (designUrl) return result.concat({ ...option, urls: designUrl });
    return result;
  }, []);
};
