import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AiFeatureList } from '../../../../../components/AiFeatureList';
import { DLPTemplateService } from '../../../../../services/ai/dlp-configs';
import { IDLPTemplate } from '../types';
import { Paths } from '../../../../../types/paths';

export const DLPTemplateList: React.FC = () => {
  const { t } = useTranslation('ai');
  const navigate = useNavigate();
  const { switchToConsoleAnalytics } = useFlags();

  const onCellHandler = ({ id }: { id: number }) => ({
    onClick: () =>
      navigate(
        switchToConsoleAnalytics ? Paths.analyticDLPEdit({ id }) : Paths.oldAnalyticDLPEdit({ id }),
      ),
  });

  const columns: ColumnProps<IDLPTemplate>[] = [
    {
      title: t('name'),
      onCell: onCellHandler,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: 'left',
      width: '25%',
    },
    {
      title: t('de_identify'),
      onCell: onCellHandler,
      dataIndex: 'deidentify_template_name',
      width: '24%',
    },
    {
      title: t('inspect_template'),
      onCell: onCellHandler,
      dataIndex: 'inspect_template_name',
      width: '24%',
    },
    {
      title: t('gcp_id'),
      onCell: onCellHandler,
      dataIndex: 'gcp_project_name',
      width: '24%',
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actions',
      fixed: 'right',
    },
  ];

  return (
    <AiFeatureList<IDLPTemplate>
      service={DLPTemplateService}
      columns={columns}
      getCreatePath={
        switchToConsoleAnalytics ? Paths.analyticDLPCreate : Paths.oldAnalyticDLPCreate
      }
      deleteConfirmationTitle={t('delete_dlp_template')}
      getDisplayName={record => record.name}
    />
  );
};
