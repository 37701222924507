import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, Tooltip } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { DropdownButton, DropdownMenu } from '../../Dropdown';
import { useDropdown } from '../../../hooks/useDropdown';
import { useIntegrations } from '../../../state/integrations';

interface IAgent {
  created_at?: string;
  external_id?: string;
  id: number;
  key: number;
  label: string;
  name: string;
  metadata?: any;
  type?: number;
  updated_at?: string;
}

export const Agents: React.FC = () => {
  const { t } = useTranslation('header');
  const location = useLocation();
  const allAgentsTitle = t('all_agents');
  const [selectedItem, setSelectedItem] = useState<string>(allAgentsTitle);
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [agentsItems, setAgentsItems] = useState<any>([]);
  const { visible, toggleDropdown, dropdownRef } = useDropdown<HTMLButtonElement>(false);
  const { oldKnowledge, isFetching } = useIntegrations();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [module, setModule] = useState<string>('');

  useEffect(() => {
    setModule(location.pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    if (module === 'knowledge' || module === 'design') {
      setDisabled(false);
      if (agentsItems.length) {
        setSelectedItem(agentsItems[0].label);
        setSelectedKey(agentsItems[0].key.toString());
      }
    } else {
      setDisabled(true);
    }
  }, [module, agentsItems]);

  useEffect(() => {
    if (oldKnowledge) {
      const agents = oldKnowledge.map(item => ({
        key: item.id,
        label: item.name,
      }));
      setAgentsItems(agents);
    } else if (!isFetching) {
      setAgentsItems([]);
      setSelectedItem(allAgentsTitle);
      setSelectedKey('');
    }
  }, [oldKnowledge]);

  const handleAgentSelect = (value: MenuInfo) => {
    if (value.key !== '/integration') {
      const selectedItem = agentsItems?.find((item: IAgent) => item.key.toString() === value.key);
      const selectedItemLabel = selectedItem?.label.toString() || selectedItem?.key;
      setSelectedItem(selectedItemLabel);
      setSelectedKey(value.key);
    }
    toggleDropdown();
  };

  return disabled ? (
    <Tooltip placement="bottom" title={`${t('disabled_dropdown')} ${module}`}>
      <div>
        <DropdownButton disabled={disabled}>
          <DropdownMenu.Text>{allAgentsTitle}</DropdownMenu.Text>
        </DropdownButton>
      </div>
    </Tooltip>
  ) : (
    <Dropdown
      menu={{
        items: agentsItems,
        selectable: false,
        onClick: handleAgentSelect,
        selectedKeys: [selectedKey],
      }}
      dropdownRender={menu => (
        <DropdownMenu.Root>
          {!!agentsItems?.length && !disabled && <DropdownMenu.Menu>{menu}</DropdownMenu.Menu>}
          <DropdownMenu.Footer
            single={!agentsItems?.length}
            to={'integration'}
            onClick={toggleDropdown}
          >
            {t('view_all_agents')}
          </DropdownMenu.Footer>
        </DropdownMenu.Root>
      )}
      placement="bottomRight"
      trigger={['click']}
      open={visible}
      destroyPopupOnHide={true}
    >
      <DropdownButton ref={dropdownRef} onClick={toggleDropdown}>
        <DropdownMenu.Text>{selectedItem}</DropdownMenu.Text>
      </DropdownButton>
    </Dropdown>
  );
};
