import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';
import { Trans, useTranslation } from 'react-i18next';
import { FallbackBehaviourForm } from '../FallbackBehaviourForm';
import { IAiIntegrationUrl, useIntegrations } from '../../../../../state/integrations';
import { unexpectedError } from '../../../../../utils/notification';
import { FallbackBehaviourValues, IFallbackConfig } from '../types';
import { FallbackConfigsService } from '../../../../../services/ai/fallback-configs';
import { useGetItem } from '../../../../../hooks/useGetItem';
import { useUpdate } from '../../../../../hooks/useUpdate';
import { Content } from '../../../../../components/Content';
import { InfoBanner } from '../../../../../components/InfoBanner';
import { ActionButton } from '../../../../../components/ActionButton';
import { formatApiErrors, getFormErrors } from '../../../../../utils/errors';
import { Space } from '../../../../../components/Space';
import { formItemClass } from '../../../../../theme';

export const FallbackBehaviourEdit: React.FC = () => {
  const { t } = useTranslation('ai');
  const { notification } = App.useApp();
  const { aiIntegration } = useIntegrations();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const { data } = useGetItem<IFallbackConfig>(
    [FallbackConfigsService.queryKey, aiIntegration?.metadata.PROJECT_ID, id],
    ({ signal }) =>
      FallbackConfigsService.find({ id, apiData: aiIntegration as IAiIntegrationUrl }, signal),
    { enabled: !!id && !!aiIntegration?.url },
  );

  const { mutate: updateItem, isLoading: isEditLoading } = useUpdate(
    [FallbackConfigsService.queryKey, aiIntegration?.metadata.PROJECT_ID as string, id],
    FallbackConfigsService.update,
  );

  const onSubmit = async (
    data: Partial<FallbackBehaviourValues>,
    setFields: FormInstance['setFields'],
  ): Promise<void> => {
    const { escalate_message, hangup_message, powered_by_twilio } = data;
    (!escalate_message || powered_by_twilio) && (data.escalate_message = '-');
    (!hangup_message || powered_by_twilio) && (data.hangup_message = '-');
    aiIntegration &&
      (await updateItem(
        { id, apiData: aiIntegration, data },
        {
          onSuccess: data => {
            notification.success({
              message: (
                <Trans t={t}>
                  {t('fallback_behav_updated_notify', { name: data.fallback_config.name })}
                </Trans>
              ),
            });
            navigate(-1);
          },
          onError: (err: any) => {
            if (err.response.status === 400) {
              const formattedErrors = formatApiErrors(
                Object.assign({}, ...err.response.data.errors),
              );
              setTimeout(() => setFields(getFormErrors(formattedErrors)), 100);
            } else notification.error(unexpectedError);
          },
        },
      ));
  };

  if (!data) return <Content imgBg={false} />;

  const initialValues: FallbackBehaviourValues = {
    name: data.name,
    escalate_message: data.escalate_message,
    hangup_message: data.hangup_message,
    is_default: data.is_default,
    powered_by_twilio: data.powered_by_twilio,
    router_id: data.router_id.toString(),
  };

  return (
    <Content imgBg={false}>
      <Space direction="vertical" size="middle">
        <InfoBanner className={formItemClass.full} message={t('fallback_behav_description')} />
        <FallbackBehaviourForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton={<ActionButton type="save" htmlType="submit" loading={isEditLoading} />}
        />
      </Space>
    </Content>
  );
};
