import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { OverlaySpinner } from '../../../../components/Spinner';
import { AnalyticsParams, AnalyticsService } from '../../../../services/analytics';
import { useIntegrations } from '../../../../state/integrations';
import { useGetItem } from '../../../../hooks/useGetItem';
import { Reports } from '../../../Analytics/Reports/types';
import { theme } from '../../../../theme';

export const InsightsIframe: React.FC = () => {
  const [currentUrl, setCurrentUrl] = useState<string>();
  const { analytics: { url, pulseId, externalId } = {} } = useIntegrations();
  const [loading, setLoading] = React.useState<boolean>(true);

  const { data } = useQuery<Reports[]>(
    [AnalyticsService.queryKeyDashboards, externalId, pulseId],
    ({ signal }) =>
      AnalyticsService.getDashboards(
        {
          projectId: externalId,
          componentId: pulseId,
          analyticsUrl: url,
        } as AnalyticsParams,
        signal,
      ),
    { enabled: !!externalId && !!pulseId },
  );

  const { data: currentReport } = useGetItem<Reports>(
    [AnalyticsService.queryKeyDashboards, externalId, currentUrl],
    ({ signal }) =>
      AnalyticsService.find({ url: currentUrl as string, analyticsUrl: url as string }, signal),
    { enabled: !!currentUrl && !!externalId && !!pulseId },
  );

  useEffect(() => {
    const currentUrl = data?.find(e => e.name === 'Insights')?.url;
    currentUrl && setLoading(true);
    setCurrentUrl(currentUrl);
  }, [data]);

  return (
    <>
      <OverlaySpinner loading={loading} />
      {currentReport && (
        <iframe
          width="100%"
          style={{ paddingTop: theme.subNavHeight }}
          height="100%"
          src={currentReport.url}
          onLoad={() => setLoading(false)}
        />
      )}
    </>
  );
};
