import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as RobotIcon } from '../../../assets/icons/robot.svg';
import useSelectedMenuItem from '../../../hooks/useSelectedMenuItem';
import { FixedNav } from '../../../components/FixedNav';
import { Paths } from '../../../types/paths';

export const DesignNav: React.FC = () => {
  const { t } = useTranslation('design');

  const menuItems = [
    {
      key: Paths.design(),
      icon: <RobotIcon width={14} height={16} />,
      label: <Link to={Paths.design()}>{t('virtual_assistant')}</Link>,
    },
  ];
  const selectedMenuItem = useSelectedMenuItem(menuItems);

  return (
    <FixedNav>
      <Menu mode="horizontal" selectedKeys={[selectedMenuItem]} items={menuItems} />
    </FixedNav>
  );
};
